import isArray from "lodash/isArray";
import { createAsyncAction, errorResult, successResult } from "pullstate";
import baseFetch from "unfetch";

import { fetch } from "api";
import { markdownConverter } from "markdown";
import { GlobalInfoStore } from "stores";

export const loadConfig = createAsyncAction(
  async () => {
    try {
      const resp = await fetch("/config");
      const payload = await resp.json();

      if (!isArray(payload)) {
        return errorResult([], "Unexpected response format");
      }

      return successResult(payload);
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error) {
        GlobalInfoStore.update((state) => {
          result.payload.forEach((rawConfigItem) => {
            if (rawConfigItem.id === "stream_url") {
              state.streamUrl = rawConfigItem.value;
            }
            if (rawConfigItem.id === "websocket_url") {
              state.websocketUrl = rawConfigItem.value;
            }
            if (rawConfigItem.id === "record_url") {
              state.protocolUrl = rawConfigItem.value;
            }
          });
        });
      }
    },
  }
);

export const loadProtocol = createAsyncAction(
  async () => {
    const { protocolUrl } = GlobalInfoStore.getRawState();

    try {
      const resp = await baseFetch(protocolUrl);

      if (resp.status !== 200) {
        return errorResult([], `Unexpected status code ${resp.status}`);
      }

      return successResult(await resp.text());
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error) {
        GlobalInfoStore.update((state) => {
          state.protocol = markdownConverter.makeHtml(result.payload);
        });
      }
    },
  }
);
