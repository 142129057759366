import React from "react";
import { Helmet } from "react-helmet-async";

import { markdownConverter } from "markdown";

const content = markdownConverter.makeHtml(`
**Celostátní fórum Pirátské strany** je [podle Stanov](https://wiki.pirati.cz/rules/st#cl_8_celostatni_forum) nejvyšším orgánem strany a zasedání se podle možností účastní každý člen strany.

> Celostátní fórum ve výlučné působnosti:
>
> * a. volí a odvolává republikové předsednictvo,
> * b. volí a odvolává členy republikového výboru volené celostátním fórem,
> * c. zřizuje a ruší komise a odbory,
> * d. volí a odvolává členy komise a vedoucího odboru,
> * e. schvaluje změny stanov,
> * f. projednává a schvaluje výroční zprávu předsedy strany,
> * g. projednává a schvaluje výroční finanční zprávu podle ZPS,
> * h. mimořádně přezkoumává rozhodnutí orgánu strany.

### Zasedání na Internetu

Zimní zasedání Celostátního fóru, z důvodu mimořádných okolnosti spojenych s mimořádným stavem, bude probihat **na Internetu**. postup zasedání na Internetu je definovan [§42a](https://wiki.pirati.cz/rules/jdr#zasedani_na_internetu) Jednacího řádu Celostátního fóra v nasledujim znění:

> **(1)** Pokud mimořádné okolnosti nedovolují konání běžného zasedání, může, v rámci krizového řízení, republikové předsednictvo pověřit předsedu strany svoláním zasedání na Internetu nebo změnou již svolaného běžného zasedání na zasedání na Internetu.
>
> **(2)** Při zasedání na Internetu jednají účastníci zasedání ve vzájemné okamžité součinnosti přes Internet s použitím určených systémů strany, případně systémů třetích stran.
>
> **(3)** Zasedání na Internetu předseda strany svolá tím, že členům řádně oznámí datum, dobu a jeho organizátora, a to nejméně 40 dnů předem. Nejméně 14 dní před začátkem zasedání organizátor oznámí zejména:
>
> * a) způsoby pro sledování veřejného přenosu ze zasedání,
> * b) způsob pro registraci přítomnosti účastníků v průběhu zasedání,
> * c) způsob pro účast v jednání zvukem a obrazem,
> * d) způsob, kterým mohou přítomní členové a příznivci v rozpravě písemně pokládat dotazy a připomínky a vyjádřit jim podporu,
> * e) způsob, kterým mohou přítomní členové předkládat písemně procedurální návrhy a vyjádřit jim podporu,
> * f) způsob pro sčítané hlasování o procedurálních návrzích,
> * g) pokyny pro účast novinářů.
>
> **(4)** Právo účasti v jednání zvukem a obrazem mají zejména:
>
> * a) předsedající a další činovníci jednání,
> * b) osoby s právem na závěrečné slovo v rozpravě k bodům k rozhodnutí,
> * c) osoby určené navrhovatelem bodu v rozpravě k jiným bodům,
> * d) další osoby, pro něž je schválen takový postup.
>
> **(5)** Jinak se při zasedání na Internetu postupuje přiměřeně jako při běžném zasedání.
>

### Další informace

* [Stanovy České pirátské strany](https://wiki.pirati.cz/rules/st)
* [Jednací řád celostátního fóra](https://wiki.pirati.cz/rules/jdr)
`);

const About = () => {
  const htmlContent = {
    __html: content,
  };
  return (
    <>
      <Helmet>
        <title>Co je to celostátní fórum? | CF 2023 | Pirátská strana</title>
        <meta
          name="description"
          content="Nevíte co je to celostátní fórum České pirátské strany? Tady se dočtete vše potřebné."
        />
        <meta
          property="og:title"
          content="Co je to celostátní fórum? | CF 2023 | Pirátská strana"
        />
        <meta
          property="og:description"
          content="Nevíte co je to celostátní fórum České pirátské strany? Tady se dočtete vše potřebné."
        />
      </Helmet>
      <article className="container container--default py-8 lg:py-24">
        <h1 className="head-alt-md lg:head-alt-lg mb-8">Celostátní fórum</h1>

        <div
          className="content-block leading-normal"
          dangerouslySetInnerHTML={htmlContent}
        />
      </article>
    </>
  );
};

export default About;
