import React from "react";
import { format } from "date-fns";

import Button from "components/Button";

const NotYetStarted = ({ startAt }) => (
  <article className="container container--wide py-8 md:py-16 lg:py-32">
    <div className="hidden md:inline-block flag bg-violet-400 text-white head-alt-base mb-4 py-4 px-5">
      Jejda ...
    </div>
    <h1 className="head-alt-base md:head-alt-md lg:head-alt-xl mb-2">
      Jednání ještě nebylo zahájeno :(
    </h1>
    <p className="text-xl leading-snug mb-8">
      <span>Jednání celostátního fóra ještě nezačalo. </span>
      {startAt && (
        <span>
          Mělo by být zahájeno <strong>{format(startAt, "d. M. Y")}</strong> v{" "}
          <strong>{format(startAt, "H:mm")}</strong>.{" "}
        </span>
      )}
      <span>Můžete si ale zobrazit program.</span>
    </p>
    <Button routerTo="/program" className="md:text-lg lg:text-xl" hoverActive>
      Zobrazit program
    </Button>
  </article>
);

export default NotYetStarted;
