import React from "react";

import Chip from "components/Chip";

export { default as Beacon } from "./Beacon";

export const steps = [
  {
    target: "body",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">Vítej na celostátním fóru 2023</h1>
        <p className="leading-snug text-base">
          Letošní Pirátské fórum bude online. Abychom to celé zvládli,
          připravili jsme tuhle aplikaci, která se snaží alespoň částečně
          nahradit fyzickou přítomnost. Nejprve si vysvětlíme, jak funguje.
        </p>
      </>
    ),
    placement: "center",
    disableBeacon: true,
  },
  {
    target: ".joyride-login",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">Jsi člen či příznivec? Přihlaš se</h1>
        <p className="leading-snug text-base">
          Pokud jsi člen strany nebo registrovaný příznivec, je rozhodně dobrý
          nápad se přihlásit. Budeš tak moci přidávat příspěvky v rozpravě a
          palcovat je.
        </p>
      </>
    ),
  },
  {
    target: ".joyride-player",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">Video stream</h1>
        <p className="leading-snug text-base">
          Zde můžeš sledovat přímý přenos z jednání. Přenos má drobné zpoždění,
          tak s tím počítej.
        </p>
      </>
    ),
    placement: "bottom",
  },
  {
    target: ".joyride-posts",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">Příspěvky v rozpravě</h1>
        <div className="leading-snug text-base space-y-2">
          <p>
            Předsedající pro každý bod programu může otevřít či uzavřít
            rozpravu. V rámci rozpravy je možné přidávat běžné diskusní
            příspěvky, nebo návrhy postupu.
          </p>
          <p>
            <strong>Běžné příspěvky</strong> se zobrazí ihned po přidání.
          </p>
          <p>
            <strong>Návrhy postupu</strong> po přidání nejprve zkontroluje
            předsedající a pokud sezná, že je takový návrh přípusný, prohlásí ho
            za{" "}
            <Chip color="blue-300" condensed>
              hlasovatelný
            </Chip>
            . Pro vyjádření podpory používej palce. Na základě míry podpory
            předsedající buď návrh označí za{" "}
            <Chip color="green-400" condensed>
              schválený
            </Chip>
            , nebo za{" "}
            <Chip color="red-600" condensed>
              zamítnutý
            </Chip>
            .
          </p>
          <p>
            U příspěvků se též zobrazuje celková míra podpory. Legenda barevného
            odlišení je následující:
          </p>

          <ul className="unordered-list unordered-list--dense">
            <li>
              <div className="px-1 text-sm font-bold inline-block bg-green-400 text-white">
                Zelenou
              </div>{" "}
              je označen příspěvek, na kterém je konsensus, nebo takový, který
              získal podporu skupiny členů.
            </li>
            <li>
              <div className="px-1 text-sm font-bold inline-block bg-yellow-400 text-grey-300">
                Žlutou
              </div>{" "}
              je označen příspěvek, který podporu teprve sbírá.
            </li>
            <li>
              <div className="px-1 text-sm font-bold inline-block bg-red-600 text-white">
                Červeně
              </div>{" "}
              je označen příspěvek, který má spíše negativní odezvu.
            </li>
            <li>
              <div className="px-1 text-sm font-bold inline-block bg-grey-125 text-grey-200">
                Šedivě
              </div>{" "}
              je označen příspěvek, který zatím není ohodnocen.
            </li>
          </ul>
        </div>
      </>
    ),
    placement: "center",
  },
  {
    target: ".joyride-filters",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">Filtrování a řazení příspěvků</h1>
        <div className="leading-snug text-base space-y-2">
          <p>
            Příspěvky v rozpravě můžeš filtrovat <strong>podle typu</strong>{" "}
            (návrhy/příspěvky), <strong>podle stavu</strong>{" "}
            (aktivní/archivované) a můžeš taky přepínat jejich{" "}
            <strong>řazení</strong> (podle podpory, podle času přidání).
          </p>
        </div>
      </>
    ),
    placement: "bottom",
  },
  {
    target: ".joyride-announcements",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">Oblast pro oznámení</h1>
        <p className="leading-snug text-base">
          V této oblasti se zobrazují oznámení podstatných událostí v rámci
          jednání, jako například nové rozhodující hlasování, nebo třeba nový
          hlasovatelný návrh postupu.
        </p>
      </>
    ),
    placement: "left",
  },
  {
    target: "body",
    content: (
      <>
        <h1 className="head-alt-sm mb-4">To je vše!</h1>
        <p className="leading-snug text-base">
          Ať se ti letošní „CFko“ líbí i v těchto ztížených podmínkách.
        </p>
      </>
    ),
    placement: "center",
  },
];
