import baseFetch from "unfetch";

import { AuthStore } from "./stores";

export const fetch = async (
  url,
  { headers = {}, expectedStatus = 200, method = "GET", body = null } = {}
) => {
  const { isAuthenticated, user } = AuthStore.getRawState();

  if (isAuthenticated) {
    headers.Authorization = "Bearer " + user.accessToken;
  }

  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  const response = await baseFetch(process.env.REACT_APP_API_BASE_URL + url, {
    body,
    method,
    headers,
    redirect: "follow",
  });

  if (!!expectedStatus && response.status !== expectedStatus) {
    throw new Error(`Unexpected status code ${response.status}`);
  }

  return response;
};
