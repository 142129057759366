import memoize from "lodash/memoize";
import { Store } from "pullstate";

/** @type {CF2021.GlobalInfoStorePayload} */
const globalInfoStoreInitial = {
  connectionState: "connecting",
  onlineMembers: 0,
  onlineUsers: 0,
  groupSizeHalf: null,
  websocketUrl: null,
  streamUrl: null,
  protocolUrl: null,
  protocol: null,
};

export const GlobalInfoStore = new Store(globalInfoStoreInitial);

/** @type {CF2021.AuthStorePayload} */
const authStoreInitial = {
  isAuthenticated: false,
  showJitsiInvitePopup: false,
  jitsiPopupDismissed: false,
};

export const AuthStore = new Store(authStoreInitial);

/** @type {CF2021.ProgramStorePayload} */
const programStoreInitial = {
  items: {},
  currentId: undefined,
  scheduleIds: [],
};

export const ProgramStore = new Store(programStoreInitial);

/** @type {CF2021.AnnouncementStorePayload} */
const announcementStoreInitial = {
  items: {},
  itemIds: [],
};

export const AnnouncementStore = new Store(announcementStoreInitial);

/** @type {CF2021.PostStorePayload} */
const postStoreInitial = {
  items: {},
  itemCount: 0,
  window: {
    items: [],
    itemCount: 0,
  },
  filters: {
    flags: "active",
    sort: "byScore",
    type: "all",
    showPendingProposals: false,
  },
};

export const PostStore = new Store(postStoreInitial);

export const getGroupByCode = memoize(
  (groupMappings, groupCode) => {
    return groupMappings.find((gm) => gm.code === groupCode);
  },
  (groupMappings, groupCode) => [groupMappings, groupCode]
);

export const getGroupsByCode = memoize((groupMappings, groupCodes) => {
  return groupCodes.map((code) => getGroupByCode(groupMappings, code));
});
