import has from "lodash/has";

import { markdownConverter } from "markdown";
import { AnnouncementStore } from "stores";
import { parseRawAnnouncement, syncAnnoucementItemIds } from "utils";

export const handleAnnouncementChanged = (payload) => {
  AnnouncementStore.update((state) => {
    if (state.items[payload.id]) {
      if (has(payload, "content")) {
        state.items[payload.id].content = payload.content;
        state.items[payload.id].contentHtml = markdownConverter.makeHtml(
          payload.content
        );
      }
      if (has(payload, "link")) {
        state.items[payload.id].link = payload.link;
      }
    }
  });
};

export const handleAnnouncementCreated = (payload) => {
  AnnouncementStore.update((state) => {
    state.items[payload.id] = parseRawAnnouncement(payload);
    syncAnnoucementItemIds(state);
  });
};

export const handleAnnouncementDeleted = (payload) => {
  AnnouncementStore.update((state) => {
    delete state.items[payload.id];
    syncAnnoucementItemIds(state);
  });
};
