import keyBy from "lodash/keyBy";
import property from "lodash/property";
import { createAsyncAction, errorResult, successResult } from "pullstate";

import { fetch } from "api";
import { AuthStore, PostStore } from "stores";
import {
  createSeenWriter,
  filterPosts,
  parseRawPost,
  postsStateMappingRev,
  postsTypeMappingRev,
  seenPostsLSKey,
} from "utils";

export const loadPosts = createAsyncAction(
  async () => {
    try {
      const resp = await fetch("/posts", { expectedStatus: 200 });
      const data = await resp.json();
      return successResult(data.data);
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error && result.payload) {
        const posts = result.payload.map(parseRawPost);

        PostStore.update((state) => {
          const filteredPosts = filterPosts(state.filters, posts);

          state.items = keyBy(posts, property("id"));
          state.itemCount = state.items.length;
          state.window = {
            items: filteredPosts.map(property("id")),
            itemCount: filteredPosts.length,
          };
        });
      }
    },
  }
);

export const like = createAsyncAction(
  /**
   * @param {CF2021.Post} post
   */
  async (post) => {
    try {
      await fetch(`/posts/${post.id}/like`, {
        method: "PATCH",
        expectedStatus: 204,
      });
      return successResult(post);
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error) {
        PostStore.update((state) => {
          state.items[result.payload.id].ranking.myVote =
            state.items[result.payload.id].ranking.myVote !== "like"
              ? "like"
              : "none";
        });
      }
    },
  }
);

export const dislike = createAsyncAction(
  /**
   * @param {CF2021.Post} post
   */
  async (post) => {
    try {
      await fetch(`/posts/${post.id}/dislike`, {
        method: "PATCH",
        expectedStatus: 204,
      });
      return successResult(post);
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error) {
        PostStore.update((state) => {
          state.items[result.payload.id].ranking.myVote =
            state.items[result.payload.id].ranking.myVote !== "dislike"
              ? "dislike"
              : "none";
        });
      }
    },
  }
);

/**
 * Add new discussion post.
 */
export const addPost = createAsyncAction(async ({ content }) => {
  try {
    const body = JSON.stringify({
      content,
      type: postsTypeMappingRev["post"],
    });
    await fetch(`/posts`, { method: "POST", body, expectedStatus: 201 });
    return successResult();
  } catch (err) {
    return errorResult([], err.toString());
  }
});

/**
 * Add new proposal.
 */
export const addProposal = createAsyncAction(async ({ content }) => {
  try {
    const body = JSON.stringify({
      content,
      type: postsTypeMappingRev["procedure-proposal"],
    });
    await fetch(`/posts`, { method: "POST", body, expectedStatus: 201 });
    return successResult();
  } catch (err) {
    return errorResult([], err.toString());
  }
});

/**
 * Hide existing post.
 */
export const hide = createAsyncAction(
  /**
   * @param {CF2021.Post} post
   */
  async (post) => {
    try {
      await fetch(`/posts/${post.id}`, {
        method: "DELETE",
        expectedStatus: 204,
      });
      return successResult();
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

/**
 * Edit post content.
 */
export const edit = createAsyncAction(
  /**
   * @param {CF2021.Post} post
   */
  async ({ post, newContent }) => {
    try {
      const body = JSON.stringify({
        content: newContent,
      });
      await fetch(`/posts/${post.id}`, {
        method: "PUT",
        body,
        expectedStatus: 204,
      });
      return successResult();
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    shortCircuitHook: ({ args }) => {
      const { user } = AuthStore.getRawState();

      if (!user) {
        return errorResult();
      }

      if (user && user.isBanned) {
        return errorResult();
      }

      return false;
    },
  }
);

/**
 * Archive post.
 */
export const archive = createAsyncAction(
  /**
   * @param {CF2021.Post} post
   */
  async (post) => {
    try {
      const body = JSON.stringify({
        is_archived: true,
      });
      await fetch(`/posts/${post.id}`, {
        method: "PUT",
        body,
        expectedStatus: 204,
      });
      return successResult();
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

/**
 *
 * @param {CF2021.ProposalPost} proposal
 * @param {CF2021.ProposalPostState} state
 */
const updateProposalState = async (proposal, state, additionalPayload) => {
  const body = JSON.stringify({
    state: postsStateMappingRev[state],
    ...(additionalPayload || {}),
  });
  await fetch(`/posts/${proposal.id}`, {
    method: "PUT",
    body,
    expectedStatus: 204,
  });
  return successResult(proposal);
};

/**
 * Announce procedure proposal.
 */
export const announceProposal = createAsyncAction(
  /**
   * @param {CF2021.ProposalPost} proposal
   */
  (proposal) => {
    return updateProposalState(proposal, "announced");
  },
  {
    shortCircuitHook: ({ args }) => {
      if (args.type !== "procedure-proposal") {
        return errorResult();
      }

      if (args.state !== "pending") {
        return errorResult();
      }

      return false;
    },
  }
);

/**
 * Announce procedure proposal.
 */
export const acceptProposal = createAsyncAction(
  /**
   * @param {CF2021.ProposalPost} proposal
   */
  ({ proposal, archive }) => {
    return updateProposalState(proposal, "accepted", { is_archived: archive });
  },
  {
    shortCircuitHook: ({ args }) => {
      if (args.proposal.type !== "procedure-proposal") {
        return errorResult();
      }

      if (args.proposal.state !== "announced") {
        return errorResult();
      }

      return false;
    },
  }
);

/**
 * Reject procedure proposal.
 */
export const rejectProposal = createAsyncAction(
  /**
   * @param {CF2021.ProposalPost} proposal
   */
  ({ proposal, archive }) => {
    return updateProposalState(proposal, "rejected", { is_archived: archive });
  },
  {
    shortCircuitHook: ({ args }) => {
      if (args.proposal.type !== "procedure-proposal") {
        return errorResult();
      }

      if (args.proposal.state !== "announced") {
        return errorResult();
      }

      return false;
    },
  }
);

/**
 * Reject procedure proposal.
 */
export const rejectProposalByChairman = createAsyncAction(
  /**
   * @param {CF2021.ProposalPost} proposal
   */
  ({ proposal, archive }) => {
    return updateProposalState(proposal, "rejected-by-chairman", {
      is_archived: archive,
    });
  },
  {
    shortCircuitHook: ({ args }) => {
      if (args.proposal.type !== "procedure-proposal") {
        return errorResult();
      }

      if (!["pending", "announced"].includes(args.proposal.state)) {
        return errorResult();
      }

      return false;
    },
  }
);

const { markSeen: storeSeen } = createSeenWriter(seenPostsLSKey);

/**
 * Mark down user saw this post already.
 * @param {CF2021.Post} post
 */
export const markSeen = (post) => {
  storeSeen(post.id);

  PostStore.update((state) => {
    state.items[post.id].seen = true;
  });
};
