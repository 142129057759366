import React from "react";

import { loadMe } from "actions/users";
import Button from "components/Button";
import { CardBody } from "components/cards";
import ErrorMessage from "components/ErrorMessage";
import { useActionState } from "hooks";
import { AuthStore } from "stores";

const JitsiInviteCard = () => {
  const { showJitsiInvitePopup, jitsiPopupDismissed } = AuthStore.useState();
  const [loading, errorMessage] = useActionState(loadMe);

  const openJitsiWindow = async () => {
    const result = await loadMe.run();

    if (!result.error) {
      window.open(result.payload.jitsi_url);
    }

    AuthStore.update((state) => {
      state.jitsiPopupDismissed = true;
    });
  };

  const dismissPopup = () => {
    AuthStore.update((state) => {
      state.jitsiPopupDismissed = true;
    });
  };

  if (!showJitsiInvitePopup) {
    return null;
  }

  if (jitsiPopupDismissed) {
    return (
      <Button
        color="violet-500"
        className="btn--fullwidth"
        onClick={openJitsiWindow}
        loading={loading}
        icon="ico--jitsi"
      >
        Připojit se k Jitsi
      </Button>
    );
  }

  return (
    <div className="lg:card lg:elevation-16 bg-violet-300 relative container-padding--zero md:container-padding--auto">
      <i className="ico--jitsi text-9xl mr-2 text-violet-500 absolute right-0 top-0 opacity-25 z-0" />
      <CardBody className="p-4 lg:p-8 text-white relative z-10">
        <div className="flex items-center justify-between mb-4">
          <h2 className="head-heavy-xs">
            <span>Pozvánka do Jitsi</span>
          </h2>
          <button
            type="button"
            onClick={dismissPopup}
            aria-label="Zavřít"
            data-tip="Zavřít"
          >
            <i className="ico--cross"></i>
          </button>
        </div>
        {errorMessage && (
          <ErrorMessage>
            Při načítání URL Jitsi kanálu došlo k problému: {errorMessage}.
          </ErrorMessage>
        )}
        <p className="leading-snug text-sm mb-4">
          Někdo tě pozval do <strong>chráněného Jitsi kanálu</strong>{" "}
          celeostátního fóra. Ke kanálu se připojíš kliknutím na tlačítko níže.
        </p>
        <Button
          color="violet-500"
          className="btn--fullwidth"
          onClick={openJitsiWindow}
          loading={loading}
        >
          Připojit se k Jitsi
        </Button>
      </CardBody>
    </div>
  );
};

export default JitsiInviteCard;
