import keyBy from "lodash/keyBy";
import property from "lodash/property";
import { createAsyncAction, errorResult, successResult } from "pullstate";

import { fetch } from "api";
import { AnnouncementStore } from "stores";
import {
  announcementTypeMappingRev,
  createSeenWriter,
  parseRawAnnouncement,
  seenAnnouncementsLSKey,
  syncAnnoucementItemIds,
} from "utils";

export const loadAnnouncements = createAsyncAction(
  async () => {
    try {
      const resp = await fetch("/announcements");
      const data = await resp.json();
      return successResult(data.data);
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error) {
        const announcements = result.payload.map(parseRawAnnouncement);

        AnnouncementStore.update((state) => {
          state.items = keyBy(announcements, property("id"));
          syncAnnoucementItemIds(state);
        });
      }
    },
  }
);

/**
 * Add new announcement.
 */
export const addAnnouncement = createAsyncAction(
  async ({ content, link, type }) => {
    try {
      const body = JSON.stringify({
        content,
        link,
        type: announcementTypeMappingRev[type],
      });
      const resp = await fetch("/announcements", {
        method: "POST",
        body,
        expectedStatus: 201,
      });
      const data = await resp.json();
      return successResult(data.data);
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

/**
 * Delete existing announcement.
 */
export const deleteAnnouncement = createAsyncAction(
  /**
   *
   * @param {CF2021.Announcement} item
   */
  async (item) => {
    try {
      await fetch(`/announcements/${item.id}`, {
        method: "DELETE",
        expectedStatus: 204,
      });
      return successResult({ item });
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

/**
 * Update an announcement.
 */
export const updateAnnouncement = createAsyncAction(
  /**
   *
   * @param {CF2021.Announcement} item
   * @param {Object} payload
   */
  async ({ item, payload }) => {
    try {
      const body = JSON.stringify(payload);
      await fetch(`/announcements/${item.id}`, {
        method: "PUT",
        body,
        expectedStatus: 204,
      });
      return successResult({ item, payload });
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

const { markSeen: storeSeen } = createSeenWriter(seenAnnouncementsLSKey);

/**
 * Mark down user saw this post already.
 * @param {CF2021.Post} post
 */
export const markSeen = (post) => {
  storeSeen(post.id);

  AnnouncementStore.update((state) => {
    state.items[post.id].seen = true;
  });
};
