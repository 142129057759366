import * as Sentry from "@sentry/react";
import { createAsyncAction, errorResult, successResult } from "pullstate";

import { fetch } from "api";
import keycloak from "keycloak";
import { AuthStore, PostStore } from "stores";
import { updateWindowPosts } from "utils";

export const loadMe = createAsyncAction(
  /**
   * @param {number} userId
   */
  async () => {
    try {
      const response = await fetch(`/users/me`, {
        method: "GET",
        expectedStatus: 200,
      });
      const data = await response.json();
      return successResult(data);
    } catch (err) {
      return errorResult([], err.toString());
    }
  },
  {
    postActionHook: ({ result }) => {
      if (!result.error) {
        AuthStore.update((state) => {
          if (state.user) {
            state.user.id = result.payload.id;
            state.user.group = result.payload.group;
            state.user.isBanned = result.payload.is_banned;
            state.user.secret = result.payload.secret || "";
          }
        });
      }
    },
  }
);

export const ban = createAsyncAction(
  /**
   * @param {number} userId
   */
  async (user) => {
    try {
      await fetch(`/users/${user.id}/ban`, {
        method: "PATCH",
        expectedStatus: 204,
      });
      return successResult(user);
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

export const unban = createAsyncAction(
  /**
   * @param {number} userId
   */
  async (user) => {
    try {
      await fetch(`/users/${user.id}/unban`, {
        method: "PATCH",
        expectedStatus: 204,
      });
      return successResult(user);
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

export const inviteToJitsi = createAsyncAction(
  /**
   * @param {number} userId
   */
  async (user) => {
    try {
      const body = JSON.stringify({
        allowed: true,
      });
      await fetch(`/users/${user.id}/jitsi`, {
        method: "PATCH",
        body,
        expectedStatus: 204,
      });
      return successResult(user);
    } catch (err) {
      return errorResult([], err.toString());
    }
  }
);

export const refreshAccessToken = async () => {
  const { isAuthenticated } = AuthStore.getRawState();

  if (!isAuthenticated) {
    return;
  }

  try {
    await keycloak.updateToken(60);
    console.info("[auth] access token refreshed");
  } catch (exc) {
    console.warn(
      "[auth] could not refresh the access token, refresh token possibly expired, logging out"
    );

    Sentry.setUser(null);

    AuthStore.update((state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.showJitsiInvitePopup = false;
      state.jitsiPopupDimissed = false;
    });

    PostStore.update((state) => {
      state.filters.showPendingProposals = false;
      updateWindowPosts(state);
    });
  }
};
